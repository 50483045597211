import React, { useEffect } from "react"
import { graphql, StaticQuery } from "gatsby" 
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const Counter = styled.div`
  height: 60vh;
  width: 100%;
  span.happy-counter {
    text-align: center;
    font-size: 72px;
    line-height: 96px;
    padding-top: 30vh;
    display: block;
    width: 100%;
    font-family: 'Montserrat';
    font-weight: bold;
  }
  span.happy-note {
    font-size: 14px;
    text-align: center;
    display: block;
    width: 100%;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 30px;
  }
`

export default function HappyPlace() {

  useEffect(() => {
    var countDownDate = new Date("Jun 15, 2022 15:37:25").getTime();
    // Update the count down every 1 second
    var x = setInterval(function() {
      // Get today's date and time
      var now = new Date().getTime();
      // Find the distance between now and the count down date
      var distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      // Display the result in the element with id="demo"
      document.querySelector('.happy-counter').innerHTML = days + "d " + hours + "h "
      + minutes + "m " + seconds + "s ";
      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        document.getElementById("demo").innerHTML = "EXPIRED";
      }
    }, 1000);
  })


  return (
    <Layout>
      <SEO title="Happy Place" keywords={[`frontend`, `developer`, `react`, `drupal`, `croatia`, `theming`, `annertech`, `ie11`]}/>
      <Counter>
        <span className="happy-counter"></span>
        <span className="happy-note">UNTIL IE11 EOL</span>
      </Counter>
    </Layout>
  )
}

